<template>
    <v-container fluid class="mt-4 bg-white border rounded shadow">
        <v-row justify="center">
            <v-col md="12">
                <v-tabs :grow="true" background-color="white" color="#d9242a">
                    <v-tab>Exp. personal</v-tab>
                    <v-tab>Exp. laboral</v-tab>
                    <v-tabs-slider color="#d9242a"></v-tabs-slider>

                    <!-- contenido de la pestaña de expediente personal -->
                    <v-tab-item>
                        <v-row>

                            <v-col cols="12" sm="12">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ml-2" color="primary" fab small dark v-on="on" @click="dialog=true, edicion=false, titulo='Registro'">
                                            <v-icon>fas fa-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Agregar documento</span>
                                </v-tooltip>
                                <v-dialog v-model="dialog" persistent max-width="600px">
                                
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{titulo}} de documentos personales</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <validation-observer ref="documento">
                                                    <v-row v-if="!edicion">
                                                        <v-col cols="12" sm="6" md="12">
                                                            <validation-provider rules="required" v-slot="{errors}">
                                                                <v-select :error-messages="errors[0]" no-data-text="No se tiene nigun documento pendiente" color="blue darken-1" v-model="documento_faltante" :menu-props="{ top: true, offsetY: false }" placeholder="Seleccione el documento" 
                                                                    :items="documentos_faltantes" item-text="documento" item-value="id_documento" label="Documento">
                                                                </v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                    
                                                    <v-row>
                                                        <v-col cols="12" sm="6" md="12">
                                                            <validation-provider rules="required|ext:pdf" ref="archivo" v-slot="{errors, validate}">
                                                                <v-file-input ref="file" color="blue darken-1" :error-messages="errors[0]" accept=".pdf" label="Documento" @change="cargar_documentos"></v-file-input>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12" sm="6" md="12">
                                                            <validation-provider rules="" v-slot="{errors}">
                                                                <v-textarea
                                                                    color="blue darken-1"
                                                                    name="input-7-1"
                                                                    label="Observaciones"
                                                                    hint="detalles del archivo"
                                                                    v-model="observaciones"
                                                                    :error-messages="errors[0]"
                                                                ></v-textarea>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                </validation-observer>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-col cols="12" class="d-flex justify-center">
                                                <v-btn color="blue darken-2" outlined @click="cerrar_modal">Cancelar</v-btn>
                                                <v-btn v-if="edicion" class="ml-2" color="blue darken-2" @click="actualizar_documento_personal()">Aceptar</v-btn>
                                                <v-btn v-else class="ml-2" color="blue darken-2" @click="registrar_documento_personal()">Aceptar</v-btn>
                                            </v-col>
                                            
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>

                            <v-col v-if="showTable" cols="12" sm="12">
                                <v-client-table :data="expediente_personal" :columns="columns" :options="options">
                                    <template slot='accion' slot-scope="personal">

                                         <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                 <v-btn small  v-on="on" color="#60cbdd" @click="visualizar_documento_personal(personal.row.nombre_archivo)">
                                                    <v-icon small>fas fa-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Visualizar documento</span>
                                        </v-tooltip>


                                       
                                    </template>

                                    <template slot='actualizacion' slot-scope="personal">
                                         <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                 <v-btn small v-on="on" color="#1867c0" @click="dialog_actualizar(personal.row)">
                                                    <v-icon small>fas fa-pen</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Actualizar documento</span>
                                        </v-tooltip>
                                    </template>
                                </v-client-table>
                            </v-col>

                            <v-col v-if="showAlert" cols="12" sm="12">
                                <v-alert align="center" dense type="info"  style="padding:0px">
                                    <strong>No se tiene registrado ningun documento</strong>
                                </v-alert>
                            </v-col>

                        </v-row>
                    </v-tab-item>

                    <!-- contenido de la pestaña de expediente laboral -->
                    <v-tab-item>
                            <v-col v-if="showTableLaboral" cols="12" sm="12">
                                <v-client-table :data="expediente_laboral" :columns="columns2" :options="options">
                                    <template slot='accion' slot-scope="laboral">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn small v-on="on" color="#60cbdd" @click="visualizar_documento_laborales(laboral.row.nombre_archivo)">
                                                    <v-icon small>fas fa-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Visualizar documento</span>
                                        </v-tooltip>
                                       
                                    </template>
                                </v-client-table>
                            </v-col>

                            <v-col v-if="showAlertLaboral" cols="12" sm="12">
                                <v-alert align="center" dense type="info" style="padding:0px">
                                    <strong>No se tiene registrado ningun documento</strong>
                                </v-alert>
                            </v-col>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>

        <v-overlay opacity=".6" :value="value" z-index="20">
            <v-row>
                <v-col cols="12" sm="12">
                    <v-row justify="center">
                        <v-col cols="12" sm="3">
                            <v-progress-circular :size="70" color="white" indeterminate />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="text-center offset-md-1" cols="12" sm="12">
                    <strong>Guardando documento...</strong>
                </v-col>
            </v-row>
        </v-overlay>
    </v-container>
    
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        data: function(){
            return{
                showTable:false,
                showAlert:false,
                showTableLaboral:false,
                showAlertLaboral:false,
                dialog: false,
                value: false,
                edicion: false,
                titulo: null,
                // datos
                expediente_personal: [],
                expediente_laboral: [],
                documentos_faltantes: [],
                // registro
                expediente_empleado_id: null,
                file: null,
                observaciones: null,
                documento_faltante: null,
                nombre_archivo: null,

                // tabla
                columns: ["index", "descripcion", "observaciones", "obligatorio", 'actualizacion', 'accion'],
                columns2: ["index", "descripcion", "observaciones", "obligatorio", 'accion'],
                options: {
                    headings: {
                        index: '#',
                        descripcion: "Descripcion",
                        observaciones: "Observaciones",
                        obligatorio : "Obligatorio",
                        actualizacion: 'Actualización',
                        accion: 'Visualizar',
                    },
                    sortable: [],
                    texts: {
                        count:"",
                        filter:"Buscar:",
                        filterPlaceholder: 'Buscar...',
                        limit:"Resultados:",
                        recordsDisplay: 'Resultados',
                        page:"Página:",
                        noResults:"No hay resultados",
                        filterBy: 'Buscar...',
                        loading:'Cargando...',
                        defaultOption: 'Seleccionar {column}'
                    },
                    filterable: false,
                    filterByColumn: false,
                    resizableColumns: false,
                    sendEmptyFilters :false,
                    childRowTogglerFirst: true,
                    showChildRowToggler: true,
                    perPage: 5,
                    perPageValues: [5, 10, 15, 20],
                    templates:{
                        index:(h,row,index) => {
                            return index;
                        },
                        observaciones: (h, row)=>{
                            return (row.observaciones == '' || row.observaciones == null ||  row.observaciones == 'null') ? 'sin comentarios': row.observaciones;
                        },
                        obligatorio: (h, row) => {
                            return (Number(row.obligatorio) == 1) ? 'Si': 'No';
                        }
                    }
                },
            }
        }, computed:{
            ...mapState(["user"]),
            ...mapState(["token"]),
            ...mapState(["baseUrl"])
        }, mounted: async function(){
            await this.load_expediente_empleado();
            this.$NProgress.done();
        }, methods: {
            load_documentos_personales_faltantes: async function (){
                try{
                    let response = await this.$http.get(`expediente/get-documentos-faltantes/${this.user.id_empleado}`)
                    if (response.data.response){
                        this.documentos_faltantes = response.data.data;
                    }else{
                        this.documentos_faltantes = [];
                    }
                }catch(exception){

                }
            },
            load_documentos_personales: async function(){
                try{
                    let response = await this.$http.get(`expediente/get-expediente-personal/${this.user.id_empleado}`)
                    if (response.data.response){
                        this.expediente_personal = response.data.data;
                        this.showTable = true;
                        this.showAlert = false;
                    }else{
                        this.showTable = false;
                        this.showAlert = true;
                    }
                }catch(exception){
                    this.showTable = false;
                    this.showAlert = true;
                }
            },
            load_expediente_empleado: async function(){
                try{
                    let response = await this.$http.get(`expediente/get-expediente-empleado/${this.user.id_empleado}`)
                    if (response.data.personales){
                        this.expediente_personal = response.data.personales;
                        this.showTable = true;
                        this.showAlert = false;
                    }else{
                        this.showTable = false;
                        this.showAlert = true;
                    }

                    if (response.data.laborales){
                        this.expediente_laboral = response.data.laborales;
                        this.showTableLaboral = true;
                        this.showAlertLaboral = false;
                    }else{
                        this.showTableLaboral = false;
                        this.showAlertLaboral = true;
                    }

                    this.documentos_faltantes = response.data.faltantes;
                }catch(exception){
                    this.showTable = false;
                    this.showAlert = true;

                    this.showTableLaboral = false;
                    this.showAlertLaboral = true;
                }
            },
            cargar_documentos: async function(event){
                
                let valid = await this.$refs.archivo.validate(event);
                if (valid){
                    this.file = event;
                }else{
                    this.file = null;
                }
            },
            registrar_documento_personal: async function(){
                await this.$refs.documento.validate().then( async (valid)=>{
                    if (valid){
                        this.value = true;
                        this.dialog = false;

                        const form = new FormData();
                        form.append('empleado_id', this.user.id_empleado)
                        form.append('usuario', this.user.id_user);
                        form.append('tipo_documento', this.documento_faltante);
                        form.append('observaciones', this.observaciones);
                        form.append('archivo', this.file);
                        form.append('movimiento', 0)
                        
                        let response = await this.$http.post('expediente/subir-archivo-personal', form);
                        if (response.data.response){
                            setTimeout(async ()=>{
                                this.value = false;
                                this.dialog = false;
                                await this.load_documentos_personales_faltantes();
                                await this.load_documentos_personales();
                            }, 1500);
                            this.reset_variable();
                        }else{
                            setTimeout(()=>{
                                this.value = false;
                                this.dialog = true;
                            }, 1500);
                        }
                    }
                });

            },
            dialog_actualizar: function(documento){
                this.expediente_empleado_id = documento.id_empleado_expediente;
                this.observaciones = documento.observaciones;
                this.nombre_archivo = documento.nombre_archivo;
                this.documento_faltante = documento.id_documento;
                this.dialog = true;
                this.edicion = true;
                this.titulo = 'Actualización';
            },
            actualizar_documento_personal: async function(){
                await this.$refs.documento.validate().then( async (valid)=>{
                    if (valid){
                        this.value = true;
                        this.dialog = false;

                        const form = new FormData();
                        form.append('empleado_expediente', this.expediente_empleado_id);
                        form.append('empleado_id', this.user.id_empleado);
                        form.append('usuario', this.user.id_user);
                        form.append('observaciones', this.observaciones);
                        form.append('nombre_archivo', this.nombre_archivo);
                        form.append('tipo_documento', this.documento_faltante);

                        form.append('movimiento', 1)
                        form.append('archivo', this.file);
                        
                        let response = await this.$http.post('expediente/subir-archivo-personal', form);
                        if (response.data.response){
                            setTimeout(async ()=>{
                                this.value = false;
                                this.dialog = false;
                                await this.load_documentos_personales_faltantes();
                                await this.load_documentos_personales();
                            }, 1500);
                            this.reset_variable();
                        }else{
                            setTimeout(()=>{
                                this.value = false;
                                this.dialog = true;
                            }, 1500);
                        }
                    }
                });
            },
            visualizar_documento_personal: function(archivo){
                window.open(`${this.baseUrl}expediente/ver-pdf-personales?token=${this.token}&file_name=${archivo}`)
            },
            visualizar_documento_laborales: function(archivo){
                window.open(`${this.baseUrl}expediente/ver-pdf-laboral?token=${this.token}&file_name=${archivo}`)
            },
            reset_variable: function(){
                this.expediente_empleado_id = null;
                this.observaciones = null;
                this.documento_faltante = null;
                this.file = null;
                this.$refs.file.reset();
                // this.$refs.fileupload.value=null;
                this.$refs.documento.reset();
            },
            cerrar_modal: function(){
                this.reset_variable()
                this.dialog = false;
            }
            
        }
    }
</script>

<style>

</style>